// validation.js
const validateEmail = (email) => {
  const result =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return result.test(String(email).toLowerCase());
};
export const validateForm = (formData) => {
  let errors = {};
  let isValid = true;

  if (!formData?.first_name?.trim()) {
    errors.first_name = "First name is required";
    isValid = false;
  } else if (/\d/.test(formData.first_name.trim())) {
    errors.first_name = "First name should not contain numbers";
    isValid = false;
  }

  if (!formData?.last_name?.trim()) {
    errors.last_name = "Last name is required";
    isValid = false;
  } else if (/\d/.test(formData.last_name.trim())) {
    errors.last_name = "Last name should not contain numbers";
    isValid = false;
  }
  if (!formData?.company_name?.trim()) {
    errors.company_name = "Company name is required";
    isValid = false;
  }
  if (!formData?.phone_number?.trim()) {
    errors.phone_number = "Phone number is required";
    isValid = false;
  } else if (!/^\d{10}$/.test(formData.phone_number.trim())) {
    errors.phone_number = "Phone number must be 10 digits";
    isValid = false;
  }

  return { isValid, errors };
};

export const validateFormSubmit = (formData) => {
  let errors = {};
  let isValid = true;
  if (!formData?.email?.trim()) {
    errors.email = "Email is required";
    isValid = false;
  } else if (!validateEmail(formData?.email?.trim())) {
    errors.email = "Email is invalid";
    isValid = false;
  }
  if (!formData?.password?.trim()) {
    errors.password = "Password is required";
    isValid = false;
  }
  return { isValid, errors };
};

export const validateConfirmEmailSubmit = (formData) => {
  let errors = {};
  let isValid = true;
  if (!formData?.email?.trim()) {
    errors.email = "Email is required";
    isValid = false;
  } else if (!validateEmail(formData?.email?.trim())) {
    errors.email = "Email is invalid";
    isValid = false;
  }
  return { isValid, errors };
};

export const validateResetSubmit = (formData) => {
  let errors = {};
  let isValid = true;
  if (!formData?.password?.trim()) {
    errors.password = "Password is required";
    isValid = false;
  }

  if (!formData?.confirmPassword?.trim()) {
    errors.confirmPassword = "Confirm password is required";
    isValid = false;
  } else if (formData?.password?.trim() !== formData?.confirmPassword?.trim()) {
    errors.confirmPassword = "Passwords do not match!";
    isValid = false;
  }
  return { isValid, errors };
};

export const validateStepOne = (formData) => {
  let errors = {};
  let isValid = true;

  if (!formData?.contact_name?.trim()) {
    errors.contact_name = "Primary contact is required";
    isValid = false;
  } else if (/\d/.test(formData.contact_name.trim())) {
    errors.contact_name = "Primary contact should not contain numbers";
    isValid = false;
  }

  if (!formData?.billing_contact_name?.trim()) {
    errors.billing_contact_name = "Billing contact is required";
    isValid = false;
  } else if (/\d/.test(formData.billing_contact_name.trim())) {
    errors.billing_contact_name = "Billing contact should not contain numbers";
    isValid = false;
  }

  if (!formData?.pod_contact_name?.trim()) {
    errors.pod_contact_name = "POD contact is required";
    isValid = false;
  } else if (/\d/.test(formData.pod_contact_name.trim())) {
    errors.pod_contact_name = "POD contact should not contain numbers";
    isValid = false;
  }

  if (!formData?.email?.trim()) {
    errors.email = "Primary email is required";
    isValid = false;
  } else if (!validateEmail(formData?.email?.trim())) {
    errors.email = "Email is invalid";
    isValid = false;
  }

  if (!formData?.billing_email?.trim()) {
    errors.billing_email = "Billing email is required";
    isValid = false;
  } else if (!validateEmail(formData?.billing_email?.trim())) {
    errors.billing_email = "Billing Email is invalid";
    isValid = false;
  }

  if (!formData?.pod_email?.trim()) {
    errors.pod_email = "POD email is required";
    isValid = false;
  } else if (!validateEmail(formData?.pod_email?.trim())) {
    errors.pod_email = "POD Email is invalid";
    isValid = false;
  }
  return { isValid, errors };
};

export const validateStepTwo = (formData) => {
  let errors = {};
  let isValid = true;
  if (!formData?.name?.trim()) {
    errors.name = "Store name is required";
    isValid = false;
  }

  if (!formData?.walmart_client_id?.trim()) {
    errors.walmart_client_id = "Client id is required";
    isValid = false;
  }
  // else if (!/^[a-zA-Z0-9]{10,30}$/.test(formData?.walmart_client_id?.trim())) {
  // errors.walmart_client_id = 'Client ID must be 10-30 alphanumeric characters';
  // isValid = false;
  // }

  if (!formData?.walmart_client_secret?.trim()) {
    errors.walmart_client_secret = "Client secret is required";
    isValid = false;
  }
  // else if (!/^[a-zA-Z0-9]{20,50}$/.test(formData?.walmart_client_secret?.trim())) {
  //   errors.walmart_client_secret = 'Client Secret must be 20-50 alphanumeric characters';
  //   isValid = false;
  // }

  return { isValid, errors };
};
