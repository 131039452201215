import { createAsyncThunk } from "@reduxjs/toolkit";
import { authApiWithToken } from "../../../utils/authApi";
import { setLoading } from "../../slices/dataLoadingSlice";
import { SuccessToast } from "../../../utils/Toaster";

import {
  setCaseLogData,
  setDashBoardData,
} from "../../slices/dashboard/dashboardSlice";

// Register User
export const getDashBoardData = createAsyncThunk(
  "get/dashboard",
  async (request, { dispatch }) => {
    try {
      const page_size = request?.perPageNum ? request?.perPageNum : 10;
      const page_num = request?.pageNum ? request?.pageNum : 1;
      dispatch(setLoading(true));
      const response = await authApiWithToken.get(
        `api/case-log/?account_id=${request?.account_id ? request?.account_id : ""}&page=${page_num}&page_size=${page_size}`,
      );
      dispatch(setLoading(false));
      if (response.data.success === true) {
        dispatch(
          setDashBoardData({
            tableData: response?.data.data,
            inprogress_count: response?.data?.inprogress_count,
            pagination: response?.data?.pagination,
            cash_reimbursed: response?.data?.cash_reimbursed,
            reimbursed_cases: response?.data?.reimbursed_cases,
          }),
        );
      }
    } catch (error) {
      console.log("error: ", error);
      dispatch(setLoading(false));
    }
  },
);

// case log details
export const getCaseLogDetails = createAsyncThunk(
  "get/caselog/details",
  async (request, { dispatch }) => {
    try {
      const caseLogId = request.id;
      dispatch(setLoading(true));
      const response = await authApiWithToken.get(
        `api/case-log-details/?case_id=${caseLogId}`,
      );
      dispatch(setLoading(false));
      if (response.data.success === true) {
        dispatch(
          setCaseLogData({
            walmartReturnsTable: response?.data?.data?.walmart_returns,
            caseDetails: response?.data?.data,
          }),
        );
      }
    } catch (error) {
      console.log("error: ", error);
      dispatch(setLoading(false));
    }
  },
);

//Add comments
export const addCaseLogComments = createAsyncThunk(
  "add/caselog/comments",
  async (request, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await authApiWithToken.post(
        `/api/comments/create/`,
        request,
      );
      dispatch(setLoading(false));
      if (response.data.success === true) {
        SuccessToast(response?.data?.message);
      }
    } catch (error) {
      console.log("error: ", error);
      dispatch(setLoading(false));
    }
  },
);
