import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  isAuthenticated: false,
  loginCreds: {},
  emailConfirm: {},
  resetPassword: {},
  isDashboardAccessible: false,
};

const userSignin = createSlice({
  name: "userSignin",
  initialState,
  reducers: {
    setLogout: () => initialState,
    setLogin: (state, action) => ({
      ...state,
      isAuthenticated: true,
      user: action.payload,
    }),
    setConfirmEmailDetail: (state, action) => ({
      ...state,
      emailConfirm: action.payload,
    }),
    setResetPasswordDetail: (state, action) => ({
      ...state,
      resetPassword: action.payload,
    }),
    setLoginDetail: (state, action) => ({
      ...state,
      loginCreds: action.payload,
    }),
    setDashboardAccessible: (state, action) => {
      return {
        ...state,
        isDashboardAccessible: action.payload,
      };
    },
  },
});

export const {
  setLogin,
  setLogout,
  setLoginDetail,
  setDashboardAccessible,
  setConfirmEmailDetail,
  setResetPasswordDetail,
} = userSignin.actions;

export default userSignin.reducer;
