import React from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";

function UnAuthorized() {
  // Retrieve the token from localStorage
  const token = localStorage.getItem("token");
  const isDashboardAccessible = useSelector(
    (state) => state.root?.login?.isDashboardAccessible,
  );

  // Conditionally render based on the presence of the token
  return !token || !isDashboardAccessible ? <Outlet /> : <Navigate to="/" />;
}

export default UnAuthorized;
