import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import LoderForStapper from "../../LoderForStapper";
import { getDashBoardData } from "../../../redux/thunk/dashboard/dashboardThunk";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getTimeStampForStore,
  loginUser,
} from "../../../redux/thunk/auth/authThunk";
import {
  resetAccStepper,
  resetTimeStampForStore,
} from "../../../redux/slices/auth/signupSlice";

const Step4 = () => {
  const dispatch = useDispatch();
  const [syncData, setSyncData] = useState(false);
  const dashBoardData = useSelector(
    (state) => state.root.dashBoardState.dashboardData,
  );
  const currentAccountDetails = useSelector(
    (state) => state.root.login?.user?.accounts,
  );
  const { signUpCreds, timeStampForStore } = useSelector(
    (state) => state.root.signup,
  );

  const navigate = useNavigate();

  const handleSubmit = () => {
    dispatch(resetTimeStampForStore());
    dispatch(resetAccStepper());
    setSyncData(false);
    let req = {
      apiData: signUpCreds,
      navigate: navigate,
    };
    dispatch(loginUser(req));
  };

  useEffect(() => {
    dispatch(getTimeStampForStore());
    dispatch(
      getDashBoardData({ account_id: currentAccountDetails?.data?.[0]?.id }),
    );
  }, []);

  useEffect(() => {
    // Immediately fetch data on mount
    setSyncData(true);

    let intervalId;
    let startTime;

    if (timeStampForStore) {
      // Convert the timestamp string to a Date object
      const storedTime = new Date(timeStampForStore).getTime();
      startTime = storedTime;
      // If no data is present, set up the interval
      if (dashBoardData?.tableData?.length <= 0) {
        intervalId = setInterval(() => {
          const currentTime = new Date().getTime();
          const elapsedTime = (currentTime - startTime) / (1000 * 60 * 60); // Time in hours

          if (elapsedTime >= 72) {
            clearInterval(intervalId);
            setSyncData(false);
          } else {
            dispatch(
              getDashBoardData({
                account_id: currentAccountDetails?.data?.[0]?.id,
              }),
            );
          }
        }, 3600);
      }
    } else {
      setSyncData(false);
    }

    return () => {
      if (intervalId) clearInterval(intervalId); // Cleanup the interval on component unmount
    };
  }, [dashBoardData?.tableData?.length, timeStampForStore]);

  return (
    <>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} sm={12} md={3}>
          <div>
            <Typography
              variant="bodyS"
              align="center"
              component="h3"
              sx={{ fontWeight: "600" }}
            >
              Sync Data
            </Typography>
            <Typography variant="bodyXS" align="center" component="p">
              You’re almost there
            </Typography>
            <div style={{ marginTop: "40px", textAlign: "center" }}>
              <LoderForStapper
                className="loader-color"
                variant={!syncData ? "determinate" : "indeterminate"}
                size={180}
                value={100}
                showLabel="true"
                syncData={!syncData}
                sx={{ mx: "auto", mt: "24px" }}
              />
            </div>
          </div>
          <Typography
            variant="bodyS"
            align="center"
            component="p"
            sx={{ fontWeight: "600", mt: 2 }}
          >
            {!syncData ? "Sync done" : "Syncing data..."}
          </Typography>
          <div style={{ textAlign: "center", marginTop: "32px" }}>
            {!syncData ? (
              <Button
                variant="contained"
                color="primary"
                align="center"
                onClick={handleSubmit}
              >
                Go to dashboard
              </Button>
            ) : (
              <Typography component="p" align="center" sx={{ fontWeight: 600 }}>
                This can take up to 72 hours to complete. We will notify you
                when it is completed.
              </Typography>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Step4;
